import { Link } from '@/routes-utils/navigation';
import { paths } from '@/routes-utils/paths';
import {
  LoaderFunction,
  Navigate,
  redirect,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router';
import { ClientOnly } from 'remix-utils/client-only';
import { signupStepVar } from '../auth.signup/signup-step-var';
import { waitlistCardContent } from '../waitlist._index/route.css';
import { cardHeading } from '../waitlist/waitlist-card/waitlist-card.css';
import { LandingSignupForm } from './landing-signup-form';
import { emailContainer, textLink } from './route.css';
import { amplifyConfigure } from '@/amplify/amplify-configure';

export const loader: LoaderFunction = () => {
  // email is available on client side only
  return redirect(paths.landing);
};

export const clientLoader = () => {
  amplifyConfigure();
  return {};
};

export default function Signup() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [searchParams] = useSearchParams();

  const email = state?.email;

  return (
    <div className={waitlistCardContent}>
      <div className={emailContainer}>
        <ClientOnly>
          {() => {
            if (!email) {
              return <Navigate to={paths.landing} replace />;
            }

            return (
              <>
                <span>{email}</span>

                <Link
                  className={textLink}
                  to={paths.landing}
                  replace
                  viewTransition
                >
                  Change email
                </Link>
              </>
            );
          }}
        </ClientOnly>
      </div>

      <h1 className={cardHeading}>Almost there</h1>

      <LandingSignupForm
        email={email}
        onSignup={(username) => {
          signupStepVar('confirmEmail');
          navigate(paths.signup, {
            state: {
              email,
              username,
            },
          });
        }}
        inviteCodeQueryParam={searchParams.get('code')}
      />
    </div>
  );
}
